import { render, staticRenderFns } from "./TraitEffectList.vue?vue&type=template&id=09c8b7ba&scoped=true&"
import script from "./TraitEffectList.vue?vue&type=script&lang=js&"
export * from "./TraitEffectList.vue?vue&type=script&lang=js&"
import style0 from "./TraitEffectList.vue?vue&type=style&index=0&id=09c8b7ba&lang=scss&scoped=true&"
import style1 from "./TraitEffectList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c8b7ba",
  null
  
)

export default component.exports